<script lang="ts">
	import cn from 'classnames';
	import moment from 'moment-timezone';
	import Heading from './Heading.svelte';
	import CalendarIcon from '$lib/images/svg/calendar.svelte';
	import Tag from './Tag.svelte';
	export let type: string;
	export let data: any; // @TODO:LUKAS, pokud mate otypovano, bude fajn doplnit
	export let isHomepage: boolean;
</script>

<a
	href={type === 'page' ?
	 data?.url != null ? data?.url : data?.slug :
	 type == 'page-search' ? '/' + data?.url :
	 '/blog/' + data?.slug + '/'}
	title={data?.title}
	class={cn('flex w-full flex-col bg-white shadow-custom2', !isHomepage && 'md:h-32 md:flex-row')}
>
	<div
		class={cn(
			'blog-hover relative h-32 w-full shrink-0 overflow-hidden',
			!isHomepage && ' md:h-full md:w-[10.625rem]'
		)}
	>
		<div
			class="absolute left-0 top-0 h-full w-full bg-cover bg-center bg-no-repeat transition-all duration-700 hover:scale-110"
			style="background-image: url({data?.feature_image.replace('http://', 'https://').replace('scootland.cz/static','scootland.cz')	})"
		/>

		<div class="absolute left-0 top-3.5 flex flex-col">
			{#each data?.tags.split(',') as tag}
				{#if tag.toString().includes('zvysenivykonu')}<Tag color="yellow">Zvýšení výkonu</Tag>{/if}
				{#if tag.toString().includes('jak-to-funguje')}<Tag color="yellow">Jak to funguje?</Tag
					>{/if}
				{#if tag.toString().includes('udrzba-opravy')}<Tag color="yellow">Údržba a opravy</Tag>{/if}
				{#if tag.toString().includes('aktuality')}<Tag color="yellow">Aktuality</Tag>{/if}
				{#if tag.toString().includes('zajimavosti')}<Tag color="yellow">Zajímavosti</Tag>{/if}
				{#if tag.toString().includes('navody')}<Tag color="yellow">Návody</Tag>{/if}
				{#if tag.toString().includes('cestovani')}<Tag color="yellow">Cestování</Tag>{/if}
				{#if tag.toString().includes('video')}<Tag color="yellow">Video</Tag>{/if}
			{/each}
		</div>
	</div>

	<div class={cn('flex grow flex-col p-4 text-left md:p-5 md:pb-6', !isHomepage && 'md:w-3/5')}>
		<Heading tag="h3" class="mb-1 text-xl leading-snug lg:text-2xl">
			{data?.title}
		</Heading>

		{#if type != 'page'}
			<div class="flex w-full gap-x-2.5">
				<CalendarIcon class="w-5 text-yellow" />
				<span class="text-gray-oslogray">{moment(data?.published_at).format('DD. MMMM YYYY')}</span>
			</div>
		{/if}

		<p class="truncated-text-oneline mt-1 leading-normal">
			{data?.excerpt.substring(0, 120)}
		</p>

		<!-- <p class="mb-3 mt-5 leading-normal">
			{data?.excerpt.substring(0, 90)}...
		</p> -->

		<!-- <div class="mt-auto flex w-full flex-row-reverse break-normal text-right font-bold uppercase">
			<span class="text-primary mt-1">
				<ArrowRightIcon class="ml-2 h-4 w-4" />
			</span>
			Číst celé
		</div> -->
	</div>
</a>
